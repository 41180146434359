/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
app-chat-room {
  display: grid !important;
}
@font-face {
  font-family: 'poppins';
  src: url('../assets/fonts/popins/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
